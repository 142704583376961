import React from "react";
import logo from "../data/img.jpeg";
export const Navigation = (props) => {
  return (
    <>
      {" "}
      <div class="top-banner">
        <div className="row"></div>
        <div className="col-md-4 col-sm-12">
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-map-marker"></i> Address
              </span>
              {props.data ? props.data.address : "loading"}
            </p>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          {" "}
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> Phone
              </span>{" "}
              {props.data ? props.data.phone : "loading"}
            </p>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          {" "}
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope"></i> Email
              </span>{" "}
              {props.data ? props.data.email : "loading"}
            </p>
          </div>
        </div>
      </div>
      <nav id="menu" className="navbar navbar-default ">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              <img src={logo} alt="" className="log" />
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  Services
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  Gallery
                </a>
              </li>
              {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
