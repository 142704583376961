import React from "react";

export const Services = (props) => {
  return (
    <div id="testimonials" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Bussiness Solution</h2>
          <p>
            Unlock your business potential with our innovative solutions. We
            provide tailored strategies and cutting-edge technology to drive
            growth, streamline operations, and enhance customer engagement.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 mb-3">
                  {/* <div class="card-basic">
                    <div class="card-header header-basic">
                      <h1>{d.name}</h1>
                    </div>
                    <div class="card-body">
                      <div class="card-element-hidden-basic">
                        <ul class="card-element-container">
                          <li class="card-element"> Responsive Design</li>
                          <li class="card-element">Intuitive Navigation</li>
                          <li class="card-element">
                            Visual Design and Branding
                          </li>

                          <li class="card-element">Page Speed Optimization</li>
                          <li class="card-element">VR Augumentation</li>
                          <li class="card-element"> Security Features</li>
                          <li class="card-element">3D Animations</li>
                          <li class="card-element">SEO Optimization</li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div class="card">
                    <div class="header color01">
                      <h2 class="card-header">{d.name}</h2>
                    </div>

                    <ul class="card-pricing-list">
                      {d.Why.map((item) => (
                        <li class="card-pricing-item included-in-price">
                          {item}
                        </li>
                      ))}

                      {/* <li class="card-pricing-item included-in-price">
                        Intuitive Navigation
                      </li>
                      <li class="card-pricing-item included-in-price">
                        Visual Design and Branding
                      </li>
                      <li class="card-pricing-item included-in-price">
                        Page Speed Optimization
                      </li>
                      <li class="card-pricing-item included-in-price">
                        VR Augumentation
                      </li>
                      <li class="card-pricing-item included-in-price">
                        Security Features
                      </li>
                      <li class="card-pricing-item included-in-price">
                        SEO Optimization
                      </li> */}
                      {/* <li class="card-pricing-item not-included-in-price">
                        Beating Around the Bush
                      </li>
                      <li class="card-pricing-item not-included-in-price">
                        Quick On the Draw
                      </li> */}
                    </ul>
                  </div>
                </div>
                // <div key={`${d.name}-${i}`} className="col-md-4">
                //   {" "}
                //   <i className={d.icon}></i>
                //   <div className="service-desc">
                //     <h3>{d.name}</h3>
                //     <p>{d.text}</p>
                //   </div>
                // </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
